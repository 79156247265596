import React, {useEffect, useState} from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import callApi from "../utils/ApiService";
import { SERVER_URL, IMAGE_API_URL, ASSET_URL } from "../config/constants";

export const Home = () => {
    const getCategoryData = useSelector((state) => state.category.data);
    const getSubCategoryData = useSelector((state) => state.subcategory.data);
    // const [getProducts, setGetProducts] = useState({});

    let categoryIndex = 1;

    let cateogorySlides = "";
    let subCategoriesByCategory = "";
    let productsByCategory = "";
    if (getCategoryData.length > 0) {
        cateogorySlides = getCategoryData.map((category) => {
            // return (<Slide className="mx-2" index={categoryIndex + 1} key={category.category_id}>
            //     <Link to={`/${category.category_id}/${category.category_slug}`}>
            //         <h5 className="text-dark py-2 mb-0 text-center">{category.category_name.toUpperCase()}</h5>
            //         <Image className="img-fluid mt-0 rounded shadow" alt={category.cateogory_name} src={`${IMAGE_API_URL}${category.category_image}`} />
            //     </Link>
            // </Slide>)
            return (
                <div className="col-12 col-sm-3 col-md-3 col-2 my-2">
                  <Link to={`/${category.category_id}/${category.category_slug}`}>
                    <div className="card">
                      <img className="card-img-top img-fluid" src={`${IMAGE_API_URL}${category.category_image}`} alt="Card image cap" />
                      <div className="card-body bg-light">
                        <h5 className="card-title text-center text-dark">{category.category_name.toUpperCase()}</h5>
                      </div>
                    </div>
                  </Link>
                </div>)
        });
        // subCategoriesByCategory = getCategoryData.map((category) => {
        //     return (
        //         <section className="my-3">
        //             <div className="container">
        //                 <h4>{category.category_name.toUpperCase()}</h4>
        //                 <CarouselProvider isPlaying="true" className="homepage-slider" naturalSlideHeight={50} naturalSlideWidth={500} totalSlides={getCategoryData.length} visibleSlides={8} step={1} isIntrinsicHeight={true}>
        //                     <Slider>
        //                         {getSubcategoryList(category.category_name, category.category_id, category.category_slug)}
        //                     </Slider>
        //                 </CarouselProvider>
        //             </div>
        //         </section>
        //     )
        // });
        // productsByCategory = getCategoryData.map((category) => {
        //     return (
        //         <section className="my-3">
        //             <div className="container">
        //                 <h4>{category.category_name.toUpperCase()}</h4>
        //                 <CarouselProvider isPlaying="true" className="homepage-slider" naturalSlideHeight={50} naturalSlideWidth={500} totalSlides={getCategoryData.length} visibleSlides={4} step={1} isIntrinsicHeight={true}>
        //                     <Slider>
        //                         {console.log(getProducts)}
        //                     </Slider>
        //                 </CarouselProvider>
        //             </div>
        //         </section>
        //     )
        // });
    };

    // useEffect(()=>{
    //     let getProductsByCategory;
    //     let productData = [];
    //     if (getCategoryData.length > 0) {
    //         cateogorySlides = getCategoryData.map((category) => {
    //             let getProductApiConfig = {
    //                 apiUrl: `${SERVER_URL}get-products-by-category/category/${category.category_id}/page/1/pageSize/18`,
    //                 apiRequest: {},
    //                 apiHeaders: {},
    //                 apiMethod: "GET",
    //             };
    //             getProductsByCategory = async () => {
    //                 let getProductdata = await getApiProducts(getProductApiConfig);
    //                 if (getProductdata.status == 1) {
    //                     if (getProductdata.data.data.length > 0) {
    //                         return getProductdata.data.data;
    //                     }
    //                 }
    //             }
    //         });
    //         // getProductsByCategory();
    //         // setGetProducts(cateogorySlides);
    //         console.log(cateogorySlides);
    //     }
    // },[])

    // function getSubcategoryList(category_name, category_id, category_slug) {
    //     let subCategoryTable = [];
    //     if (getSubCategoryData.length > 0) {
    //         let subcategories = getSubCategoryData.filter(subcategory => subcategory.category_name == category_name);
    //         let categoryIndex = 0;
    //         subcategories.map((subcategory) => {
    //             subCategoryTable.push(
    //                 <Slide className="mx-2" index={categoryIndex + 1} key={subcategory.sub_category_id}>
    //                     <Link to={`/${category_id}/${category_slug}/${subcategory.sub_category_id}/${subcategory.sub_category_slug}`}>
    //                         <p className="text-dark py-2 mb-0 text-center">{subcategory.sub_category_name.toUpperCase()}</p>
    //                     </Link>
    //                 </Slide>
    //             )
    //         });
    //     };
    //     return subCategoryTable;
    // }

    // let getApiProducts = async (apiConfig) => {
    //     const callapi = await callApi(apiConfig);
    //     if (callapi.status == 4) {

    //     } else {
    //         let getResult = callapi;
    //         return getResult;
    //     }
    // };

    return (
        <>
            <CarouselProvider naturalSlideHeight={520} naturalSlideWidth={1536} totalSlides={4} isPlaying={true} interval={3000} className="homepage-slider">
                <Slider>
                    <Slide index={0}>
                        <Image className="img-fluid" src={`${ASSET_URL}images/BANNER-01.png`} />
                    </Slide>
                    <Slide index={1}>
                        <Image className="img-fluid" src={`${ASSET_URL}images/BANER-02.jpg`} />
                    </Slide>
                    <Slide index={2}>
                        <Image className="img-fluid" src={`${ASSET_URL}images/BANER-03A-copy.jpg`} />
                    </Slide>
                    <Slide index={3}>
                        <Image className="img-fluid" src={`${ASSET_URL}images/BANER-04.jpg`} />
                    </Slide>
                </Slider>
                <ButtonBack><i className="fas fa-2x text-white fa-angle-left"></i></ButtonBack>
                <ButtonNext><i className="fas fa-2x text-white fa-angle-right"></i></ButtonNext>
            </CarouselProvider>
            <section className="my-3">
                <div className="container">
                    <h4>What are you looking for?</h4>
                    {/* <CarouselProvider className="homepage-slider" naturalSlideHeight={240} naturalSlideWidth={280} totalSlides={getCategoryData.length} visibleSlides={3} step={1} isIntrinsicHeight={true}>
                        <Slider>
                            {cateogorySlides}
                        </Slider>
                        <ButtonBack style={{ left: "-30px" }}><i className="fas fa-2x text-dark fa-angle-left"></i></ButtonBack>
                        <ButtonNext style={{ right: "-30px" }}><i className="fas fa-2x text-dark fa-angle-right"></i></ButtonNext>
                    </CarouselProvider> */}
                    <div className="row">
                    {cateogorySlides}
                    </div>
                </div>
            </section>
            {/* {subCategoriesByCategory}
            {productsByCategory} */}
        </>
    );
}